import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { timer } from 'rxjs';
import { NotificationItemComponent } from '../notification-item/notification-item.component';
import { EmptyComponent } from '../../empty/empty.component';
import { NotificationService } from 'src/app/shared/services/notification.service';

@Component({
  selector: 'app-notification-list',
  templateUrl: './notification-list.component.html',
  styleUrls: ['./notification-list.component.scss'],
  imports: [CommonModule, EmptyComponent, NotificationItemComponent],
  standalone: true
})
export class NotificationListComponent implements OnInit {
  constructor(public notificationService: NotificationService) {}

  ngOnInit(): void {
    if (this.notificationService.hasNewNotifications$.value) {
      timer(5000).subscribe(() => {
        this.notificationService.readNotifications().subscribe(() => {
          this.notificationService.hasNewNotifications$.next(false);

          this.notificationService.getNotifications().subscribe();
        });
      });
    }
  }
}
