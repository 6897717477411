import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OktaCallbackComponent } from '@okta/okta-angular';

import { NftMarketPageComponent } from './pages/nft-market-page/nft-market-page.component';
import { FanAreaPageComponent } from './pages/fan-area-page/fan-area-page.component';

import { AuthGuard } from './guards/auth.guard';
import { HomePageComponent } from './pages/home-page/home-page.component';

const routes: Routes = [
  {
    path: '',
    children: [
      { path: '', component: HomePageComponent, pathMatch: 'full' },
      {
        path: 'login/callback',
        component: OktaCallbackComponent
      },
      {
        path: 'discord/callback',
        loadChildren: () => import('./pages/profile-page/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'signin',
        component: HomePageComponent
      },
      {
        path: 'signup',
        component: HomePageComponent
      },
      {
        path: 'content',
        loadChildren: () => import('./pages/content-page/content.module').then(m => m.ContentModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile-page/profile.module').then(m => m.ProfileModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'store',
        loadChildren: () => import('./pages/store-page/store.module').then(m => m.StoreModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'rewards',
        loadChildren: () => import('./pages/rewards-page/rewards.module').then(m => m.RewardsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'nft-market',
        component: NftMarketPageComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'fan-area',
        component: FanAreaPageComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'auction',
      //   component: AuctionPageComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'players',
        loadChildren: () => import('./pages/players-page/players.module').then(m => m.PlayersModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'notifications',
        loadChildren: () => import('./pages/notifications-page/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'streams',
        loadChildren: () => import('./pages/streams-page/streams.module').then(m => m.StreamsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'videos',
        loadChildren: () => import('./pages/videos-page/videos.module').then(m => m.VideosModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'article/:id',
        loadChildren: () => import('./pages/article-page/article.module').then(m => m.ArticleModule),
      },
      {
        path: 'charity',
        loadChildren: () => import('./pages/charity-page/charity.module').then(m => m.CharityModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'terms',
        loadChildren: () => import('./pages/terms-page/terms.module').then(m => m.TermsModule),
      },
      {
        path: 'policy',
        loadChildren: () => import('./pages/policy-page/policy.module').then(m => m.PolicyModule),
      },
      {
        path: 'faqs',
        loadChildren: () => import('./pages/faqs-page/faqs.module').then(m => m.FaqsModule),
      },
      {
        path: 'packages',
        loadChildren: () => import('./pages/packages-page/packages.module').then(m => m.PackagesModule),
      },
      {
        path: 'events/dpa-claim',
        loadChildren: () => import('./pages/events-claim-page/events.module').then(m => m.EventsModule),
      },
      { path: '**', redirectTo: '/content', pathMatch: 'full' }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: false, scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
